import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';

const Header = () => {

    const handleDownload = (event) => {
        event.preventDefault();
        window.location.href = '../whitepaper.pdf';
    }
    return (<motion.div className="bg-[#BA40F0] border-b-2 border-[#4d235f]   fixed w-full text-[#fff] py-5 md:py-5  text-[16px] font-semibold flex  md:flex-row z-20 gap-5 items-center  justify-between md:justify-between  px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

        

    >
        <div className='flex flex-row md:justify-center justify-between items-center text-[24px] md:text-[30px] w-full md:gap-10 md:w-[80%] mx-auto'>
            <div className=' flex flex-row md:mr-5 items-center gap-3 justify-start rounded-full'>
                <img className='w-[70px] ' src="../img/logo.png" alt="logo" />
                <h1 className='md:text-[35px]'>$FLAPPY</h1>
            </div>
            <a href="#" className='hidden md:block'>Home</a>
            <a href='../wp.pdf' className='hidden md:block'>Whitepaper</a>
            <a href="https://www.dextools.io/app/ether/pair-explorer/0x891d92646f7f8074195b5a25c8a8eff0fc45c4e4?utm_source=telegram&utm_medium=mevfree" className='hidden md:block'>Charts</a>
            <a href="https://t.me/FlappyToken_SOL">Telegram</a>
            <a href="https://x.com/FlappyToken_SOL" className='hidden md:block'>Twitter</a>
        </div>

    </motion.div>);
}

export default Header;