import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaArrowRight, FaTelegram, FaTwitter } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Hero = ({ pryColor
    , secColor }
) => {

    const socialData = [
        {
            imgUrl: "svg-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "svg-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "svg-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "svg-3",
            link: 'https://twitter.com'
        },

    ]

    const buyData = [
        {
            id: 1,
            title: "Create a Wallet",
            body: "Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io."
        },
        {
            id: 2,
            title: "Get Some SOL",
            body: "Have SOL in your wallet to switch to $FLAPPY. If you don’t have any SOL, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet."
        },
        {
            id: 3,
            title: "Go to Raydium",
            body: "Go to raydium.io/swap in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $FLAPPY token address into Raydium, select FLAPPY, and confirm. When Phantom prompts you for a wallet signature, sign."
        },
        {
            id: 4,
            title: "Switch SOL for $FLAPPY",
            body: "Switch SOL for $FLAPPY. We have 0 taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility."
        }
    ]

    AOS.init({ once: true });
    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return <div className=" text-[#fff] items-center mt-40 md:w-[80%] px-2 md:px-4 mx-auto justify-between gap-4  w-[100vw] flex flex-col ">


        <div className="flex flex-col md:flex-row items-center justify-between gap-5 ">
            <motion.div className="md:w-[50%] w-full flex flex-col gap-5"
                variants={slideIn('left', 'spring', 0.7, 0.4)}
                initial="hidden"
                whileInView="visible" viewport={{ once: true }}
            >
                <h1 className="text-[14px] md:text-[24px]">Contract yet to be released</h1>
                <p className="text-[24px]">Meet Flappy, HOPPY the Frog's first bat friend from the book "The Night Riders," written by renowned author and illustrator Matt Furie, who is also the creator of the popular character Pepe the Frog. </p>
                <img src="../img/banner.jpg" alt="" />
            </motion.div>
            <motion.div

                variants={slideIn('right', 'spring', 1.3, 0.4)}
                initial="hidden"
                whileInView="visible" viewport={{ once: true }}>

                <img src="../img/hero.png" alt="hero" />
            </motion.div>

        </div>

        <motion.div
            variants={slideIn('left', 'spring', 0.7, 0.4)}
            initial="hidden"
            whileInView="visible" viewport={{ once: true }}
        >
            <h1 className="text-[24px] py-4">Flappy the bat saying farewell to his friend HOPPY the frog</h1>
            <img src="../img/banner-2.jpg" alt="" />
        </motion.div>

        <motion.div id="about" className="text-center flex flex-col gap-5"
            data-aos="fade-right" data-aos-duration="250"
            data-aos-delay="250"
        >
            <h1 className="font-bold text-[30px]">The Story of Flappy</h1>
            <p className="text-[20px]">
                Meet Flappy, HOPPY the Frog's first bat friend from the book "The Night Riders," written by renowned author and illustrator Matt Furie, who is also the creator of the popular character Pepe the Frog.
                "The Night Riders" holds a special place in Furie's collection of works as it not only introduces the lovable character of HOPPY the Frog but also serves as the inspiration and predecessor to Pepe, who would later gain significant recognition on the internet.
                In the enchanting world of "The Night Riders," Flappy emerges as an endearing character, bringing a new dimension to FLAPPY's adventures. Flappy is an adorable bat who loves gaming. Flappy quickly establishes himself as a beloved companion to FLAPPY and the other inhabitants of their nocturnal universe.
            </p>
        </motion.div>


        <div className="flex flex-row justify-between items-center">
            <div className="w-[50%] hidden md:block"></div>
            <div data-aos="fade-right" data-aos-duration="250"
                data-aos-delay="250"><img src="../img/pic-1.jpg" alt="" /></div>
        </div>
        <div className="flex flex-row-reverse justify-between items-center">
            <div className="w-[50%] hidden md:block"></div>
            <div data-aos="fade-left" data-aos-duration="250"
                data-aos-delay="500"><img src="../img/pic-2.jpg" alt="" /></div>
        </div>
        <div className="flex flex-row justify-between items-center">
            <div className="w-[50%] hidden md:block"></div>
            <div data-aos="fade-right" data-aos-duration="250"
                data-aos-delay="750"><img src="../img/pic-3.jpg" alt="" /></div>
        </div>
        <div className="flex flex-row-reverse justify-between items-center">
            <div className="w-[50%] hidden md:block"></div>
            <div data-aos="fade-left" data-aos-duration="250"
                data-aos-delay="1000"><img src="../img/pic-4.jpg" alt="" /></div>
        </div>
        <div className="flex flex-row justify-between items-center">
            <div className="w-[50%] hidden md:block"></div>
            <div data-aos="fade-right" data-aos-duration="250"
                data-aos-delay="1250"><img src="../img/pic-5.jpg" alt="" /></div>
        </div>

        <div className="my-5 w-full  md:px-0 md:w-[80%] text-center flex flex-col items-center justify-center" data-aos="fade-up" data-aos-duration="250"
            data-aos-delay="1000">
            <h1 className="text-[40px] font-bold">How to Buy</h1>
            <div className="grid grid-cols-2 gap-6 w-full  md:w-[60%]">
                {
                    buyData.map((items) => ((
                        <div key={items.id} className="bg-[#E062FF] hover:scale-105 duration-300 rounded-[20px] text-[#fff] p-2  md:p-4">
                            <h1 className="font-bold text-lg">{items.title}</h1>
                            <p className="text-sm">{items.body}</p>
                        </div>
                    )))
                }
            </div>



        </div>

        <div className="my-5  w-[80%] text-center" data-aos="fade-up" data-aos-duration="250"
            data-aos-delay="1000">
            <p>The website effectively employs satire and parody, using clever humor and irony to deliver its message. It is a satirical platform that does not intend to offer any intrinsic value or financial returns. Similarly, $FLAPPY, the meme coin, is a lighthearted cryptocurrency with no expected financial gains, designed solely for amusement purposes.</p>
        </div>

        <div className="flex my-5 rounded text-[#fff] px-5 py-2 bg-[#n]  text-[32px] flex-row gap-2 items-center justify-center">
            <a className="transform duration-300 hover:scale-110" href="https://t.me/flappytoken_sol">
                <FaTelegram />
            </a>
            <a className="transform duration-300 hover:scale-110" href="https://twitter.com/flappytoken_sol">
                <FaTwitter />
            </a>

        </div>
                <div className="mx-auto font-bold text-3xl">
                <a href='../wp.pdf' className='block'>Whitepaper</a>
                </div>

    </div>;
}

export default Hero;