import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineCopy } from "react-icons/ai"
import MarqueeImage from "../utils/MarqueeImage";
import CA from "../utils/Ca";

import AOS from 'aos';
import 'aos/dist/aos.css'

const About = () => {
    
    AOS.init({ once: true });

    const [canPlay, setCanPlay] = useState(false);

    return (<div className="w-full relative  text-center mt-10 px-4 md:px-10 ">

        <div className="  text-[#fff] bg-opacity-80 ">
          
        </div>
        
    </div>);
}

export default About;