const Footer = () => {
    const socialData = [
        {
            imgUrl: "social-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "social-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "social-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "social-2",
            link: 'https://coingecko.com'
        },
        {
            imgUrl: "social-3",
            link: 'https://twitter.com'
        },

    ]
    return (<div className="w-full bg-[#BA40F0] border-t-2 border-[#4f295f]" >
       
        <div className="w-[90%] md:w-[80%] mx-auto py-5 px-4 items-start justify-center gap-2 flex flex-col text-center text-[#fff] font-light text-[12px]">
        <p className="my-5 text-center ">© 2023 FLAPPY</p>

        </div>
        
    </div>);
}

export default Footer;