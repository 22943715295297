import { useEffect } from 'react';
import { useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Footer, Header, Hero } from './components';
import Home from './pages/Home';
function App() {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    const innerH = window.innerHeight;
    const innerW = window.innerWidth;
    setHeight(innerH);
    setWidth(innerW);
  }, []);

  const pryColor = "#DCE5F7";
  const secColor = "#4E6DC4";
  const interColor = "";

  

  return (
    <Router>
    
      <Switch>
        <div className="App relative">
      
        <Header />
   
          <Route exact path="/">
            <Home
          
              pryColor={pryColor}
              secColor={secColor}
              interColor={interColor}
            />
          </Route>
        </div>
        
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
